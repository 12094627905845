<template>
    <div>
        <h1>Info pages</h1>
        <div v-if="pages">
            <div v-for="(page, index) in pages" :key="index">
                <v-card class="mx-auto pa-5 my-3" max-width="90%">
                    <v-card-text>
                        <v-text-field v-model="page.title" label="Title" outlined></v-text-field>
                        <v-textarea v-model="page.html" label="html" outlined></v-textarea>
                        <v-card class="my-3">
                            <v-card-title>metaData:</v-card-title>
                            <v-card-text>
                                <v-text-field v-model="page.metaData.title" label="Title" outlined></v-text-field>
                                <v-text-field v-model="page.metaData.description" label="Description"
                                    outlined></v-text-field>
                                <v-text-field v-model="page.metaData.keywords" label="Keywords" outlined></v-text-field>
                                <v-text-field v-model="page.metaData.robots" label="Robots" outlined></v-text-field>
                                <v-text-field v-model="page.metaData.viewport" label="Viewport" outlined></v-text-field>
                                <v-text-field v-model="page.metaData.canonical" label="Canonical" outlined></v-text-field>
                                <v-text-field v-model="page.metaData.author" label="Author" outlined></v-text-field>
                                <v-text-field v-model="page.metaData.referrer" label="Referrer" outlined></v-text-field>
                            </v-card-text>
                        </v-card>
                        <div>
                            <v-card max-width="80%" class="mx-auto pa-3">
                                <v-card-title>preview:</v-card-title>
                                <v-card-text>
                                    <div v-html="page.html"></div>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="savePage(page)">Save</v-btn>
                        <v-btn color="red" @click="deletePage(page)">Delete</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
        <div>
            <v-card class="mx-auto pa-5 my-3" max-width="90%">
                <v-card-text>
                    <v-text-field v-model="newTitle" label="Title" outlined></v-text-field>
                    <v-text-field v-model="newHtml" label="html" outlined></v-text-field>
                    <v-card class="my-3">
                        <v-card-title>metaData:</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="newMetaData.title" label="Title" outlined></v-text-field>
                            <v-text-field v-model="newMetaData.description" label="Description" outlined></v-text-field>
                            <v-text-field v-model="newMetaData.keywords" label="Keywords" outlined></v-text-field>
                            <v-text-field v-model="newMetaData.robots" label="Robots" outlined></v-text-field>
                            <v-text-field v-model="newMetaData.viewport" label="Viewport" outlined></v-text-field>
                            <v-text-field v-model="newMetaData.canonical" label="Canonical" outlined></v-text-field>
                            <v-text-field v-model="newMetaData.author" label="Author" outlined></v-text-field>
                            <v-text-field v-model="newMetaData.referrer" label="Referrer" outlined></v-text-field>
                        </v-card-text>
                    </v-card>
                    <div>
                        <v-card max-width="80%" class="mx-auto">
                            <v-card-title>preview:</v-card-title>
                            <v-card-text>
                                <div v-html="newHtml"></div>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="addPage">Add</v-btn>
                </v-card-actions>
            </v-card>
        </div>
        <!-- <v-btn block color="primary" @click="saveMetaData">Save</v-btn> -->
    </div>
</template>
  
<script>
import fire from "@/main";

export default {
    name: 'infoPages',
    data() {
        return {
            pages: [],
            newTitle: '',
            newHtml: '',
            newMetaData: {
                title: '',
                description: '',
                keywords: '',
                robots: '',
                viewport: '',
                canonical: '',
            },
            metaData: [
                "title", "description", "keywords", "robots", "viewport", "canonical", "author", "referrer"
            ],
        };
    },
    created() {
        // Load metaData from Firebase Realtime Database
        fire.database().ref('admin/infoPages').on('value', (snapshot) => {
            if (snapshot.val()) {
                this.pages = snapshot.val();
                //add metaData to each page if it doesn't exist
                for (let page in this.pages) {
                    if (!this.pages[page].metaData) {
                        this.pages[page].metaData = {
                            title: '',
                            description: '',
                            keywords: '',
                            robots: '',
                            viewport: '',
                            canonical: '',
                        };
                    }
                }
            }

        });
    },
    methods: {
        savePage(page) {
            // Save metaData to Firebase Realtime Database
            let pageTitle = page.title.toLowerCase();
            //clean white spaces
            pageTitle = pageTitle.replace(/\s/g, '');
            fire.database().ref('admin/infoPages/' + pageTitle).set(page);
        },
        deletePage(page) {
            // Save metaData to Firebase Realtime Database
            fire.database().ref('admin/infoPages/' + page.title).remove();
        },
        async addPage() {
            if (this.newTitle && this.newHtml) {
                let pageTitle = this.newTitle.toLowerCase();
                //clean white spaces
                pageTitle = pageTitle.replace(/\s/g, '');
                await fire.database().ref('admin/infoPages/' + pageTitle).set({ title: pageTitle, html: this.newHtml, metaData: this.newMetaData });
                this.newTitle = ''
                this.newHtml = ''
                this.newMetaData = {
                    title: '',
                    description: '',
                    keywords: '',
                    robots: '',
                    viewport: '',
                    canonical: '',
                };
            }
        },
    }
}
</script>
  
<style scoped>
/* Add your custom styles here */
</style>
  